import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { SupportedCities, SupportedCountries } from '@wanda-space/types'
import { keys } from 'ramda'
import React from 'react'
import { useIntl } from 'react-intl'
import { updateUserServiceArea } from '../api-client/routes/users'
import { useCurrentAccount } from '../hooks/useCurrentAccount'
import { getFlagEmoji } from '../utils/flags'
import { formatCity, formatCountry } from '../utils/formatters'
import { errorToToastOptions } from './ErrorMessage'
import { TechSupportLink } from './TechSupportLink'

export function UserMissingCityModal() {
  const toast = useToast()
  const { formatMessage } = useIntl()
  const { data: user, refetch } = useCurrentAccount()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const mutation = useMutation(
    async (city: SupportedCities) => {
      await updateUserServiceArea({
        id: user!.id,
        serviceArea: city,
        token: await getAccessTokenSilently(),
      })
    },
    {
      onSuccess: (_, city) => {
        refetch()
        toast({
          title: 'City updated',
          description: `Your user was assigned to ${formatCity(city)}`,
          status: 'success',
          isClosable: true,
        })
      },
      onError: (error) => {
        toast(
          errorToToastOptions({
            error,
            formatMessage,
            title: 'Error updating route stop timing',
            isClosable: true,
          }),
        )
      },
    },
  )

  if (!isAuthenticated || user?.city || mutation.isSuccess) {
    return null
  }

  return (
    <Modal
      isOpen={true}
      onClose={() =>
        toast({
          description: 'You must select a city to continue',
          status: 'info',
          isClosable: true,
        })
      }
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="2xl" mb="0">
            Hi {user?.address?.firstName?.split(' ')[0]} 👋
          </Text>
        </ModalHeader>
        <ModalBody>
          <Text>
            It looks like there is no city assigned to your account. Please select the city you work
            in below. If you need to change this later ask in <TechSupportLink /> on slack.
          </Text>
          {keys(SupportedCountries)
            .sort()
            .map((country) => (
              <Box key={country}>
                <Text fontSize="lg" fontWeight="semibold" pt="4" pb="2">
                  {getFlagEmoji(country)} {formatCountry(country)}
                </Text>

                <Flex flexDir="column" m="0 auto">
                  {keys(SupportedCities)
                    .filter((city) => city.includes(country))
                    .sort()
                    .map((city) => (
                      <Button
                        key={city}
                        mb="4"
                        w="80%"
                        variant="outline"
                        isLoading={
                          mutation.isLoading && mutation.variables === SupportedCities[city]
                        }
                        onClick={() => mutation.mutate(SupportedCities[city])}
                      >
                        {formatCity(city)}
                      </Button>
                    ))}
                </Flex>
              </Box>
            ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
