import { ListItem, UnorderedList, type UseToastOptions } from '@chakra-ui/react'
import { values } from 'ramda'
import React from 'react'
import type { IntlShape } from 'react-intl'
import { v4 as uuid } from 'uuid'
import { isApiError } from '../api-client'

export type ValidationIssue = {
  property: string
  message: string
}

export function ErrorMessage({
  error,
  formatMessage,
}: { error: unknown; formatMessage: IntlShape['formatMessage'] }): React.ReactNode {
  if (typeof error === 'string') {
    return error
  }
  if (!(error instanceof Error)) {
    return 'An error occurred'
  }
  if (!isApiError(error) || !error.body) {
    return error.message
  }
  if (!error.details) {
    return (error.body.description || error.message) as string
  }
  if (error.details.localizationKey === 'errors.validation') {
    const issues = error.details.issues ?? {}
    return (
      <UnorderedList>
        {values(issues).flatMap((issue) => {
          if (Array.isArray(issue)) {
            return issue.map((i) => (
              <ListItem key={values(i).join('')}>{`${i.property}: ${i.message}`}</ListItem>
            ))
          }
          return (
            <ListItem
              key={values(issue).join('')}
            >{`${issue.property}: ${issue.message}`}</ListItem>
          )
        })}
      </UnorderedList>
    )
  }
  return formatMessage({
    id: error.details.localizationKey,
    defaultMessage: error.details.description,
  })
}

type ErrorToToastOptions = Partial<Omit<UseToastOptions, 'description'>> & {
  error: unknown
  formatMessage: IntlShape['formatMessage']
}

export const errorToToastOptions = ({
  error,
  formatMessage,
  ...defaults
}: ErrorToToastOptions): UseToastOptions => {
  const options: UseToastOptions = {
    id: uuid(),
    title: 'Request failed',
    duration: 5000,
    status: 'error',
    position: 'bottom',
    isClosable: true,
    ...defaults,
    description: <ErrorMessage error={error} formatMessage={formatMessage} />,
  }
  return options
}
