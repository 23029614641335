import React from 'react'

import { ArrowRightIcon } from '@wanda-space/noelle'
import { type HandoverDto, HandoverPossessorType } from '../types/handover'
import { Possessor } from './Possessor'
import { WarehouseLocation } from './WarehouseLocation/WarehouseLocation'

export function HandoverTitle({ handover }: { handover: HandoverDto }) {
  return HandoverPossessorType[handover.targetPossessor.type] ===
    HandoverPossessorType.WAREHOUSE_OUTBOUND ? (
    <>
      Picking{' '}
      {!!handover.targetPossessor.id && (
        <WarehouseLocation id={handover.targetPossessor.id} showWarehouse />
      )}
    </>
  ) : (
    <>
      <Possessor
        possessor={{
          ...handover.currentPossessor,
          type: HandoverPossessorType[handover.currentPossessor.type],
        }}
      />
      <ArrowRightIcon color="inherit" h="1rem" />
      <Possessor
        possessor={{
          ...handover.targetPossessor,
          type: HandoverPossessorType[handover.targetPossessor.type],
        }}
      />
    </>
  )
}
