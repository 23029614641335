import { z } from 'zod'

const validationIssueSchema = z.object({
  property: z.string(),
  message: z.string(),
})

const apiErrorDetailsSchema = z.object({
  localizationKey: z.string(),
  description: z.string(),
  issues: z.record(z.string(), validationIssueSchema.or(z.array(validationIssueSchema))).optional(),
})

type ApiErrorDetails = z.infer<typeof apiErrorDetailsSchema>

export class ApiError extends Error {
  public fingerprint: string[]
  public details?: ApiErrorDetails

  constructor(
    public readonly url: string,
    public readonly options: { method: string },
    public readonly response: Response,
    public readonly body?: Record<string, unknown>,
  ) {
    super(`Request to ${url} failed with ${response.statusText} (${response.status})`)
    Object.setPrototypeOf(this, ApiError.prototype)
    this.name = this.constructor.name
    this.fingerprint = [
      this.url,
      this.options.method,
      this.response.status.toString(),
      typeof this.body?.wandaCode === 'string' ? this.body.wandaCode : JSON.stringify(this.body),
    ]
    const result = apiErrorDetailsSchema.safeParse(this.body)
    if (result.success) {
      this.details = result.data
    }
  }

  status(): number {
    return this.response.status
  }

  shouldReportToSentry(): boolean {
    const isImage502 = this.response.url.includes('image') && this.response.status === 502
    return this.response.status >= 500 && !isImage502
  }
}

export const isApiError = (error: unknown): error is ApiError => error instanceof ApiError
